<template>
  <div>
    <b-card>
      <csi-form :is-user="true" />
    </b-card>
    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>
<script>
import { BCard, BButton } from 'bootstrap-vue'
import CsiForm from '@/views/External/Csi/CsiForm.vue'

export default {
  name: 'Add',
  components: {
    CsiForm,
    BCard,
    BButton,
  },
  computed: {
    saveStatus() {
      return this.$store.getters['csi/getCsiRowSaveStatus']
    },
    itemData() {
      return this.$store.getters['csi/getCsiRow']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          id_com_brand: null,
          id_com_location: null,
          sdate: null,
          edate: null,
          count: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('csi/csiRowView', this.$route.params.id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$store.dispatch('csi/csiRowSave', this.itemData)
    },
  },
}
</script>
